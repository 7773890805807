<template>
  <main>
    <section>
      <b-container class="ombre">
        <b-col class="p-0">
          <h1 v-html="$t('csu.title')"></h1>
        </b-col>

        <hr class="trait" />

        <b-row>
          <b-col>
            <ToC markdown="csu.md" />
            <div v-html="$t('csu.md')"></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import ToC from '../components/ToC.vue';

export default {
  components: {
    ToC,
  },
};
</script>

