<template>
  <div
    id="toc"
    v-html="toc"
  ></div>
</template>

<script>
export default {
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },

  computed: {
    toc() {
      const html = document.createElement('div');
      html.innerHTML = this.$t(this.markdown);
      const headings = html.getElementsByTagName('*');
      let toc = `<h2>${this.$t('toc')}</h2><ul>`;
      let level = 0;

      for (let i = 0; i < headings.length; i += 1) {
        if (headings[i].tagName === 'H2') {
          if (level === 1) {
            toc += '</li>';
          } else if (level === 2) {
            toc += '</ul>';
          }
          if (headings[i].children.length > 0
            && headings[i].children[0].tagName === 'IMG') {
            toc += `<li><a href="#${headings[i].id}">${headings[i].children[0].getAttribute('alt')}</a>`;
          } else {
            toc += `<li><a href="#${headings[i].id}">${headings[i].textContent}</a>`;
          }
          level = 1;
        }
        if (headings[i].tagName === 'H3') {
          if (level === 1) {
            toc += '<ul>';
          }
          toc += `<li><a href="#${headings[i].id}">${headings[i].textContent}</a></li>`;
          level = 2;
        }
      }
      toc += '</ul>';
      return toc;
    },
  },
};
</script>

<style lang="scss">
#toc {
  width: 40%;
  background: #fbf5ff;
  padding: .75rem 1.25rem;
  border-radius: .5rem;

  @media (min-width: 768px) {
    width: 40%;
    float: right;
    margin: 0 0 1rem 1rem;
  }

  h2 {
    font-size: 1.5rem;
    color: #1f1f1f;
    text-align: center;
  }

  > ul {
    list-style: none;
    padding: 0;

    > li {
      margin-bottom: .5em;
    }

    ul {
      list-style-type: disc;
      margin-bottom: 0;
    }

    a {
      border: none;
      background: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
