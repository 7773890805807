<template>
  <section
    id="what"
    ref="what"
  >
    <b-container class="px-md-0">
      <b-row>
        <b-col
          lg="7"
        >
          <web-screen :img="`${$t('/')}img/nextcloud.png`" />
        </b-col>
        <b-col
          class="px-3"
          lg="5"
        >
          <h2
            class="sr-only"
            v-html="$t('hos.what.title')"
          ></h2>
          <div
            class="mt-4"
            v-html="$t('hos.what.md')"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </section>

</template>

<script>
import WebScreen from '../../../commons/components/WebScreen.vue';

export default {
  components: {
    WebScreen,
  },
};
</script>

<style lang="scss">
#what {
  padding: 4rem 0;
}
</style>
