<template>
  <main>
    <section
      id="intro"
      class="main-row"
    >
      <b-container>
        <b-row class="mx-0">
          <b-col
            class="box"
            cols="12"
            sm="8"
            md="7"
            lg="6"
            xl="5"
          >
            <h1 v-html="$t('meta.title')"></h1>
            <h2 v-html="$t('meta.lead')"></h2>
            <b-row class="actions">
              <b-col
                class="w-100"
                cols="9"
                lg="6"
              >
                <a
                  class="btn btn-lg btn-primary"
                  href="https://www.frama.space/new"
                  v-html="$t('menu.signup')"
                ></a>
              </b-col>
              <b-col
                class="w-100"
                cols="9"
                lg="6"
              >
                <a
                  class="btn btn-lg btn-outline-secondary"
                  href="https://www.frama.space/login/"
                  v-html="$t('menu.signin')"
                ></a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$refs['what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0 d-none"
          >
            <span>En savoir plus</span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <what />

    <limit />

    <news />

    <how />

    <why />

    <who />

    <sfs />

    <outro />
  </main>
</template>

<script>
import What from './home/What.vue';
import Limit from './home/Limit.vue';
import News from '../components/News.vue';
import How from '../../commons/components/hos/How.vue';
import Why from './home/Why.vue';
import Who from '../../commons/components/hos/Who.vue';
import Sfs from '../../commons/components/sfs/Main.vue';
import Outro from '../../commons/components/hos/Outro.vue';

export default {
  components: {
    What,
    Limit,
    News,
    How,
    Why,
    Who,
    Sfs,
    Outro,
  },

  data() {
    return {
      email: '',
    };
  },
};
</script>

<style lang="scss">
#home {
  background: #fff
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="600px" height="600px" viewBox="0 0 100 100"><path d="M5.5 12a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 46a2 2 0 10.001-3.999A2 2 0 006 46zM31.5 8a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-3 30a2 2 0 10.001-3.999A2 2 0 0028.5 38zm16-10a2.5 2.5 0 000-5 2.5 2.5 0 000 5zM40 17.5a1 1 0 100-2 1 1 0 000 2zm-10 31a1 1 0 100-2 1 1 0 000 2zm-12.5-25a1 1 0 100-2 1 1 0 000 2zM77.53 24.742a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-21.5-3.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm31.5 15.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-22.5 11.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm28-38a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-25 3.5a2 2 0 100-4 2 2 0 000 4zm23 35.5a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-37-16a1 1 0 100-2 1 1 0 000 2zM29.5 74a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM8 70.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM39.5 86a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM17 97.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm28-38a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM20 63a2 2 0 10.001-3.999A2 2 0 0020 63zm23 35.5a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-37-16a1 1 0 100-2 1 1 0 000 2zM77.53 74.242a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-12.5 23.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm14.5-40a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-3 30a2 2 0 100-4 2 2 0 000 4zm14.5 11a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm1.5-21a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-4.5-10.5a1 1 0 100-2 1 1 0 000 2zm-34 15.5a1 1 0 100-2 1 1 0 000 2z" fill="%23e2e8f0" fill-opacity=".3"/></svg>');
  position: relative;
}

#intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, .2));
  padding: 1rem 0 3rem;
  position: relative;

  @media (max-width: 480px) {
    max-height: 700px;
  }

  &::before, &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%,  100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%,  100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%,  100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%,  100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url(../../public/img/opengraph/home.jpg);
    background-repeat: no-repeat;
    background-position: 40% 50%;
    background-size: cover;
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 88%);
    clip-path: polygon(0 0,100% 0,100% 100%,0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0,100% 0,100% 98%,0 95%);
      clip-path: polygon(0 0,100% 0,100% 98% ,0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .box {
    border-radius: .5rem;
    background: linear-gradient(-65deg, #fff2, #fff8);
    padding-top: 1rem;
    padding-bottom: 1rem;

    margin-top: 5rem;
    margin-bottom: 5rem;

    @media (max-width: 992px) {
      margin-top: 3rem;
    }

    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }

  h1{
    color: var(--f-f9);
    font-size: 4.5rem;
    font-weight: 300;
    text-shadow: #fff9 0 0 3px;

    margin-bottom: 3rem;

    @media (max-width: 992px) {
      font-size: 4rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 512px) {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  }

  h2 {
    color: var(--f-f9);
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.25;
    margin: 0;

    @media (max-width: 992px) {
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  p {
    color: var(--f-d1);
  }

  .actions {
    margin-top: 4rem;

    @media (max-width: 768px) {
      margin-top: 3rem;
    }

    .btn {
      align-items: center;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin: .5rem 0;
      width: 100%;
    }

    .w-100 {
      display: flex;
    }

    .btn-outline-secondary {
      background: #fff;
      border-color: transparent;

      &:hover, &:focus, &:active {
        background-color: #4a5568;
        border-color: #4a5568;
        color: #fff;
      }
    }
  }

  .btn-outline-light {
    @media (min-width: 768px) {
      border: transparent;
    }
  }

  #learn-more {
    label {
      color: var(--f-f9);
      background: none;
      text-decoration: none;

      i::before {
        color: var(--f-f9);
      }
    }

    span {
      background: linear-gradient(180deg,#0000,#fff4,#0000);
      border-radius: 1rem;
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }
}

#who {
  background: var(--f-g1);
}

#more {
  display: none;
}

/* SFS */
#support {
  background:
    url('../../public/img/gattino-galileo.png') no-repeat 10% calc(100% + 40px),
    linear-gradient(to bottom, var(--f-g1), transparent);

  .col-lg-7 .ombre {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

#f-sfs + div { /* #outro parent */
   background:
    transparent
    url('../../public/img/gattino-galileo.png')
    no-repeat
    10% calc(100% + 20px);
}

/* Framanav */
#home #fp-alert {
  background: var(--f-d1);
}

.f-bs4 #f-footer {
  border: none !important;
}
</style>
